<template>
	<div>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page"
				:pagination="table.pagination"
			/>
		</section>
	</div>
</template>

<script>
	import ClientsTableSample from "@/components/ClientsTableSample";
	import CardComponent from "@/components/CardComponent";
	import RemoveModal from "@/components/RemoveModal";
	import {
		BankAccountService as Service,
		BankService,
		BankListService,
	} from "@/services";

	export default {
		name: "Tables",
		components: {
			CardComponent,
			ClientsTableSample,
		},
		data() {
			return {
				table: {
					page: {
						title: "Banka Hesap Listesi",
						icon: "bank",
						actions: [
							{
								title: "Banka Hesabı Oluştur",
								type: "success",
								icon: "plus",
								action: () => {
									this.$router.push({
										name: "bankAccountCreateOrUpdate",
									});
								},
								perm: "BankAccount.Create",
							},
							{
								title: "Filtrele",
								type: "info",
								icon: "sync",
								action: () => {
									this.$refs.table.loadAsyncData();
								},
							},
						],
					},
					filter: [
						{
							type: "input",
							value: null,
							field: "name",
							name: "Hesap Adı",
						},
						{
							type: "input",
							value: null,
							field: "iban",
							name: "IBAN",
						},
						{
							type: "input",
							value: null,
							field: "group",
							name: "Grup",
						},
						{
							type: "enum",
							value: "STANDARD",
							field: "type",
							name: "Tür",
							enumType: "bankAccountTypes",
						},
						{
							type: "enum",
							value: "ENABLED",
							field: "status",
							name: "Durum",
							enumType: "bankAccountStatuses",
						},
						{
							type: "select",
							value: null,
							data: [
								{ uuid: 1, name: "Auto" },
								{ uuid: 0, name: "Manual" },
							],
							field: "isAuto",
							name: "Auto Bank",
						},
						{
							type: "select",
							value: null,
							data: [
								{ uuid: 1, name: "FAST" },
								{ uuid: 0, name: "NOT FAST" },
							],
							field: "fast",
							name: "FAST",
						},
					],
					settings: {
						service: Service,
						method: "list",
						columns: [
							{
								field: "name",
								label: "Banka Hesap Adı",
								sortable: true,
							},
							{
								field: "iban",
								label: "IBAN",
								sortable: true,
							},
							{
								field: "name",
								label: "Banka",
								column: "row",
								renderHtml: true,
								viewMethod: (val) => {
									let logo =
										process.env.VUE_APP_SITE_URL +
										"logos/banks/papara.png";
                  if (val.bank.type === "PAYFIX")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/payfix.png"
                  if (val.method === "ISYERIMPOS")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/isyerimpos.svg"
                  if (val.method === "NAYS")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/nays.svg"
                  if (val.method === "CMT")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/cmt.png"
                  if (val.method === "PAYMAGO")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/paymago.png"
                  if (val.method === "PAYCO")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/payco.png"
                  if (val.method === "PAYTURCA")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/payturca.jpg"
                  if (val.name === "VEVO PAPARA")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/vpapara.svg"
									if (
										val.bank.type === "BANK_TRANSFER" ||
										val.bank.type === "FOREX"
									) {
										logo = val.bankList?.logo;
									}
									return `<img width="70" style="max-width:70px" src="${logo}" />`;
								},
							},
							{
								field: "type",
								label: "Tür",
								sortable: true,
								viewMethod: (val) =>
									this.enums.bankAccountTypes[val],
							},
							{
								field: "showType",
								label: "Gösterim Türü",
								sortable: true,
								column: "row",
								renderHtml: true,
								viewMethod: (val) => {
									let sites = "";
									val.sites.map(
										(item) =>
											(sites +=
												"" + item.site.name + ", ")
									);
									return val.showType !== "PRIVATE"
										? this.enums.bankAccountShowTypes[
												val.showType
										  ]
										: sites.replace(/,\s*$/, "");
								},
							},
							{
								field: "dailyLimit",
								label: "Min",
								column: "row",
								renderHtml: true,
								viewMethod: (data) => {
									return `₺${this.numberFormat(
										data.minimumLimit
									)}`;
								},
							},
							{
								field: "dailyLimit",
								label: "Max",
								column: "row",
								renderHtml: true,
								viewMethod: (data) => {
									return `₺${this.numberFormat(
										data.maximumLimit
									)}`;
								},
							},
							{
								field: "isAuto",
								label: "Oto",
								sortable: true,
								renderHtml: true,
								column: "row",
								viewMethod: (val) => {
									return val.method === "BANK_TRANSFER"
										? `<span class="tag ${
												val.isAuto
													? "is-success"
													: "is-info"
										  }">${
												val.isAuto ? "OTO" : "MANUAL"
										  }</span>`
										: "-";
								},
							},
							{
								field: "fast",
								label: "FAST",
								sortable: true,
								renderHtml: true,
								column: "row",
								viewMethod: (val) => {
									return val.method === "BANK_TRANSFER"
										? `<span class="tag ${
												val.fast
													? "is-success"
													: "is-warning"
										  }">${
												val.fast ? "FAST" : "NOT FAST"
										  }</span>`
										: "-";
								},
							},
							{
								field: "status",
								label: "Durum",
								sortable: true,
								renderHtml: true,
								viewMethod: (val) =>
									`<span class="tag ${
										val === "ENABLED"
											? "is-success"
											: val === "RESERVED"
											? "is-warning"
											: "is-danger"
									}">${
										val === "ENABLED"
											? "Aktif"
											: val === "RESERVED"
											? "Yedek"
											: "Pasif"
									}</span>`,
							},
							{
								field: "sequence",
								label: "Sıra",
								sortable: true,
							},
							{
								field: "group",
								label: "Grup",
							},
							{
								field: "creator.username",
								label: "Oluşturan",
							},
							{
								field: "createdAt",
								label: "Kayıt T.",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTimeS
									),
							},
							{
								field: "actions",
								column: "id",
								label: "İşlemler",
								sortable: false,
								width: "100px",
								actions: [
									{
										title: "Düzenle",
										type: "success",
										column: "row",
										action: (data) =>
											this.$router.push({
												name: "bankAccountCreateOrUpdate",
												params: { id: data.uuid },
											}),
										perm: "BankAccount.Edit",
									},
									{
										column: "row",
										title: "Sil",
										type: "danger",
										perm: "BankAccount.Delete",
										action: (data) => {
											this.openModal({
												component: RemoveModal,
												props: {
													id: data.uuid,
													name: data.name,
													service: data.service,
													type: "Banka Hesabı",
												},
												close: this.$refs.table
													.loadAsyncData,
											});
										},
									},
								],
							},
						],
					},
					pagination: {
						paginate: 20,
						page: 1,
						sortField: "name",
						sortOrder: "asc",
						defaultSortOrder: "asc",
					},
				},
			};
		},
		mounted() {
			this.getBanks();
			this.getBankList();
		},
		methods: {
			getBanks() {
				BankService.all({ status: 1 }).then(({ data }) => {
					this.table.filter.unshift({
						type: "select",
						value: null,
						data,
						field: "bankId",
						name: "Yöntem",
					});
				});
			},
			getBankList() {
				BankListService.all().then(({ data }) => {
					this.table.filter.unshift({
						type: "select",
						value: null,
						data,
						field: "bankListId",
						name: "Banka",
					});
				});
			},
		},
	};
</script>
